<template>
    <main>
       <div class="container">
           <div class="row mt-4 justify-content-center">
               <div class="col-lg-8">
                    <div class="card shadow-xs border-0">
                        <div class="card-body p-lg-5 w-100 wallet-head border-0 rounded-3 pb-0">
                            <h1 class="font-xxl fw-800 mb-lg-5">MCAKE Wallet</h1>
                             <div class="row justify-content-left">
                                <div class="col-lg-8">
                                    <div class="m-c-wallet">
                                        <div class="row">
                                            <div class="col-lg-3 text-center">
                                                <img class="icon" src="/theme/img/logo.svg">
                                            </div>
                                            <div class="col-lg-8">
                                                <h1 class="m-wl-bal">{{mcakeBal}} MCAKE</h1>
                                                <div>
                                                    <p  v-if="tokenWalletAddress" class="m-wl-addrs"><input readonly :value="tokenWalletAddress"> <i :data-value="tokenWalletAddress" class="copytoclipboard mico-clone" @click="copyToclip"></i> </p>
                                                    <h5  v-if="tokenWalletAddress" class="m-val-head"><a target="_blank" :href="'https://solscan.io/account/'+tokenWalletAddress+'#splTransfer'">View On Solscan</a> </h5>
                                                <p  v-if="this.userWallets.solana" class="card-info">Wallet Connected : <span class="w-name" v-html="this.userWallets.solana.walletType?this.userWallets.solana.walletType:'none'"></span></p>
                                                </div>
                                            </div>
                                        </div>
                                   </div>                                    
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-lg-5 p-4 w-100 border-0 sec-2" v-if="vestingInfo.status">
                            <div class="row">
                                 <div class="col-lg-12">
                                     <div class="stake-con">
                                        <h1>MCAKE Vesting Program</h1>
                                        <div class="info">
                                            <h3>Total Vesting : {{vestingInfo.totalVesting}} MCAKE </h3>
                                            <h3>Already Vested : {{vestingInfo.vested}}  MCAKE</h3>
                                            <h3>Releasable : {{ (vestingInfo.totalVesting - vestingInfo.vested)}} MCAKE</h3>
                                            <h3>Start Date : {{new Date(vestingInfo.startDate).toDateString()}}</h3>
                                            <h3 v-if="vestingInfo.nextDate">Next Release Date : {{new Date(vestingInfo.nextDate).toDateString()}}</h3>
                                            <h3>End Date : {{new Date(vestingInfo.endDate).toDateString()}}</h3>
                                            <h3>Vesting Address : {{vestingInfo.benfAddress}} </h3>
                                        </div>
                                     </div>
                                 </div>
                            </div>
                        </div>
                   </div>
               </div>
           </div>
       </div>
    </main>
</template>
<style scoped>
.m-wl-bal , .m-val-head{
    color: var(--app-text-default-color);
}
.m-wl-addrs{
    color: var(--app-text-default-color);
    user-select: text;
}
.m-val-head a{
    color: var(--secondary-color);
}
.m-wl-addrs input{
    user-select: text;
    background: transparent;
    border: none;
    box-shadow: none;
     color: var(--app-text-default-color);
}
.stake-con{
 cursor: initial;
}
.stake-con h1{
    text-align: left;
}
.stake-con .info{
    width: 100%;
    margin-top: 30px;
   
}
.stake-con .info *{
   
    text-align: left;
}
.stake-con .info h3{
    margin-top: 22px;
}
.m-c-wallet , .stake-con{
    width: 100%;
    background:#eaeded;
    padding: 16px;
    border-radius: 10px;
}
.m-c-wallet .icon{
    max-width: 80px;
}
.w-name{
    text-transform: capitalize;
}
.card-info{
    font-weight: 500;
}
.sec-2{
    padding-top: 0px !important;
}
</style>
<script>
import noty from 'noty'
import axios from '@/plugins/axios'
import axiosMain from 'axios'

export default {
    data () {
        return {
             userWallets:{},
             vestingInfo:{},
             isSolConnected:false,
             tokenWalletAddress:'',
             mcakeBal:0
        }
    },
    methods: {
         getWalletInfo:async function(){
            var walletsInfo = await axios.get('/api/user/mywallets')
            this.userWallets = walletsInfo.data.data.userWallets;
            this.vestingInfo = walletsInfo.data.data.vesting
        },
        copyToclip:function(){
            var item = document.querySelector('.m-wl-addrs input');
            item.select();
            item.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(item.value);
            new noty({text: 'Mcake Address copied' , type:"success", layout:"bottomRight" , timeout:1000}).show();
        },
        getMcakeTokenInfo:async function(){
            if(this.userWallets.solana){
                if(this.userWallets.solana.address){
                    this.isSolConnected=true;
                    var self= this;
                    axiosMain({
                        method: 'post',
                        url: this.$store.state.solChain,
                        headers: { 'Content-Type': 'application/json'},
                        data : {
                            jsonrpc: "2.0",id: 1,method: "getTokenAccountsByOwner",
                            params: [this.userWallets.solana.address,{ mint:this.$store.state.mintAddress},{ encoding: "jsonParsed"}]
                        },
                    }).then(function (response) {
                        if(response.data.result.value[0]){
                            self.tokenWalletAddress = response.data.result.value[0].pubkey
                            self.mcakeBal = response.data.result.value[0].account.data.parsed.info.tokenAmount.uiAmount
                        }   
                     })
                    .catch(function (error) { console.log(error);});
                }   
            }else{
               this.isSolConnected=false
            }
        }
    },
    mounted:async function(){
        await this.getWalletInfo();
        this.getMcakeTokenInfo();
        setInterval(() => {
            this.getMcakeTokenInfo()
        }, 1000 * 10);
    }
}
</script>